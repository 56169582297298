$(document).ready(function () {
  $('body').removeClass('no-js');
});

$('.project-slider').slick({
  centerMode: true,
  dots: true,
  centerPadding: '60px',
  slidesToShow: 2,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        centerMode: false,
        dots: true,
        centerPadding: '40px',
        slidesToShow: 1
      }
    }
  ]
});

$(function() {
  $('.textarea').focus(function () {
    $(this).prev('label').hide();
  })
    .blur(function () {
      $(this).prev('label').show();
    });
});

$(function() {
  $(window).on("scroll", function() {
    if($(window).scrollTop() > 25) {
      $(".mod_navigation").addClass("bg-gray-light");
    } else {
      $(".mod_navigation").removeClass("bg-gray-light");
    }
  });
});

$('a[href*="#"]').on('click',function(e) {
  e.preventDefault();
  var target = this.hash;
  var $target = $(target);
  $('html, body').stop().animate({
    'scrollTop': $target.offset().top
  }, 900, 'swing', function () {
    window.location.hash = target;
  });
});

$( '.navbar-toggler' ).on('click',function ()
  {
    $('.mod_navigation').toggleClass('bg-gray-light');
  }
);

/* *********************************
 * Activate IE Polyfill for object-fit:cover
 * *********************************/
$(function () {
  objectFitImages()
});
